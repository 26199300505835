import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab, Form, Button, Breadcrumb } from "react-bootstrap"
import {People_Email, People_NegoID} from "../../queries/common_use_query"
import NoImage from "../../images/no-image.png"
import {CustomLinks} from "../../components/common/utils"

import Modal from "react-bootstrap/Modal"
import MemberForm from "../forms/member-form"

// Negotiator details
const NegoDetailsAuction = (nego_id) =>{

    const { loading, error, data } = People_NegoID(nego_id);

    // console.log("nego_info =====>", data);
    return data;
}
// Negotiator details

const PastAuctionsCard = (props) => {
    const [isOpen,setIsOpen] = useState(false);
    const [membDet,setMembDet] = useState('');

    const MemberPopup = (item) =>{
        setMembDet(item);
        setIsOpen(true);
    }


    // Negotiator details
    var split_nego_id = props.propertyNegoId && props.propertyNegoId?.split('/');

    var nego_info = props.propertyNegoId && split_nego_id ? NegoDetailsAuction(split_nego_id[0]) : NegoDetailsAuction(props.propertyNegoId);
    // Negotiator details

    return (
        <>
            
                <div className="news-wrapper">
                    <div className="news-img img-zoom past-auctions-img-wrapper auction-img-details">
                        <Link to={props.auctionUrl+"/"} state={{"lot_no":props.lotNumber, page_url:props.page_url}}>
                            {
                                props.propertyImages && props.propertyImages[0]?.url?.length > 0 ?
                                <picture>
                                    <source media="(min-width:992px)" srcSet={props.propertyImages && props.propertyImages[0]?.url} />
                                    <img src={props.propertyImages && props.propertyImages[0]?.url} alt={props?.propertyDisplayAddress+" -  Strettons"} />
                                </picture>
                                :
                                <picture>
                                    <source media="(min-width:992px)" srcSet={NoImage} />
                                    <img src={NoImage} alt={props?.propertyDisplayAddress+" -  Strettons"} />
                                </picture>
                            }
                            {
                                ((props.Offer && props.Offer !== "Unsold") && props.auctionTag !== "available-lots") &&
                                <div className="offer-text"><a className="w-auto">{props.Offer.replace('ReferToAuctioneer', 'Refer To Auctioneer')}</a></div>
                            }
                                                                    
                        </Link>
                    </div>

                    <div className="news-details auction-details">
                         <h5>
                            <Link to={props.auctionUrl+"/"} state={{"lot_no":props.lotNumber, page_url:props.page_url}}>
                                {props.lotNumber && <address className="lot_number">Lot {props.lotNumber}</address>}
                                {props?.propertyDisplayAddress}
                            </Link>
                        </h5>
                        {
                            props?.propertyDisplayAddress && 
                            <div className="price address">
                                {props?.extra?.tagline ? props?.extra?.tagline : ""}
                            </div>
                        }
                        
                        
                        <div className="price offc_text">
                            {
                                props?.building?.length > 0 && <>
                                <i className="icon-office"></i>
                                {(props.building.toString()).replace(',', ', ')}
                                </>
                            }
                        </div>
                        

                        {
                        props?.propertyPrice && 
                        <div className="office">
                            <i className="icon-price-yellow"></i>
                            {
                                props.Offer === "Sold" ? props?.extra?.resultPrice
                                : props?.propertyPrice && "Guide Price £"+props?.propertyPrice?.toLocaleString()+"*"

                            }
                        </div>
                        } 
                    </div>

                    {
                        nego_info?.peoples?.length > 0 && <>
                        
                        <div className="property-info">
                            <div className="contact-person">
                                <div className="contact-img">
                                    <picture>
                                        <Link to={`${CustomLinks.our_people}${CustomLinks.find_team}/${nego_info?.peoples[0]?.URL}/`}>
                                            <img src={nego_info?.peoples[0]?.Image.url} alt={nego_info?.peoples[0]?.Name} />
                                        </Link>
                                    </picture>
                                </div>
                                <div className="contact-info">
                                    <h3><Link to={`${CustomLinks.our_people}${CustomLinks.find_team}/${nego_info?.peoples[0]?.URL}/`}>{nego_info?.peoples[0]?.Name}</Link></h3>
                                    <a href={"tel:" + nego_info?.peoples[0]?.Phone_Number} className="tel w-auto">{nego_info?.peoples[0]?.Phone_Number}</a>
                                    <a href={"javascript:;"} onClick={() => MemberPopup(nego_info?.peoples[0])} className="mail w-auto"> / Email</a>
                                </div>
                            </div>
                        </div>
                        </>
                    } 
                </div>

            <Modal
                id="popup_modal_form"
                show={isOpen}
                onHide={() => setIsOpen(false)}
                backdrop="static"
                keyboard={false}
                className="member-contact-modal"
            > 
                <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Contact - {membDet.Name}</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <MemberForm to_email_id={membDet.Email_ID} to_user_id={membDet.Form_Queue_ID} formName={`Individual Contact ${membDet.Name}`} formLabel={`Person ${membDet.Name}`} />
                </Modal.Body>
            </Modal>
            
        </>
    )
}

export default PastAuctionsCard