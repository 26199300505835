import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import { navigate } from "@reach/router"
import { Container, Row, Col, Tabs, Tab, Form, Button, Breadcrumb } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll";
import { useLocation } from "@reach/router"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import dateformat from "dateformat"

import Layout from "../../../../components/layout";
import Connect from "../../../../components/PropertyDetails/Connect/Connect";
import AuctionCard from "../../../../components/Auctions/PastAuctionCard"
import {CustomLinks} from "../../../../components/common/utils"
import SEO from "../../../../components/seo";
import BrandLogo from "../../../../images/logo002.svg";
import _ from "lodash"
import {AuctionsPropertyPage} from "../../../../queries/common_use_query"
import Counter  from '../../../../components/Home/Counter/Counter.js'

//properties(where:{auctionId: $id, status_nin:["PostPoned", "Withdrawn"], publish:true})
const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    properties(where:{auctionId: $id, status_nin:["PostPoned"], publish:true}) {
      id
      crm_id
      auctionId
      auctionDate
      auctionType
      search_type
      status
      department
      display_address
      title
      price
      images
      slug
      crm_negotiator_id
      alt_lot_number
      building
      extra
    }
  }
`;

const PastAuctionsDetails = (props) => {
    let slug = props.location.pathname.split('/');
    let id = slug[slug.length - 2];

    // Past Auction details query
    const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
        variables: { id: id }
    });
    // Past Auction details query

    const { loading:article_loading, error:article_error, data:article_data } = AuctionsPropertyPage();

    // Social share
    const location = useLocation();

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
        
    const [Shareicons,setShareicons] = React.useState(false);
    
    const openShareicons = () => {
        setShareicons(true);
        if(Shareicons === true) {
        setShareicons(false);
        }
    }

    const [modalBookViewformOpen,setBookViewingformOpen] = React.useState(false);
    
    const openBookViewformModal = () => {
        setBookViewingformOpen(true);
    }

    const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'Share - social icons',
        'formType': event + ' - ' +shareurl,
        'formId': 'Share',
        'formName': 'Share',
        'formLabel': 'Share'
        });    
    }
    // Social share

    var auction_date = props.location?.state?.auctionDate ? props.location?.state?.auctionDate : "";

    var article_page = article_data?.articles?.length > 0 && article_data?.articles[0];
    var counter_module = article_page?.Modules?.filter(Module => Module.__typename === "ComponentComponentBannerBottom");

     // State to hold sorted properties
     const [sortedProperties, setSortedProperties] = useState([]);

     useEffect(() => {
         if (!loading && data && data.properties) { 
                let sorted =  _.orderBy(data.properties, ['alt_lot_number', 'extra.lotNumber'],['asc', 'asc']);
             setSortedProperties(sorted);
         }
         if(!loading && data.properties.length === 0){
            navigate("/auctions/past-auctions/")
         }
     }, [data, loading]);

    return (
        loading ? 
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" className="new-loader">
                    <img className="logo-white" src={BrandLogo} alt="logo"/>
                </div>
            </div>
        </section>
        :
        <Layout GQLPage="" Layout="Without_Banner" classNames={"search-header person-header details-header" } popular_search_slug={"auctions"}>
            
            <SEO title={"Past Auction Details"} description={"Past Auction Details"} />

            {/* ======= Banner Bottom ======= */}
            {
                counter_module?.length > 0 && counter_module?.map((Module, i) =>{
                    return(
                    <Counter Module={Module} Layout={article_page.Layout} />
                    )
                })
            }
            {/* ======= Banner Bottom ======= */}

            <section className="property-nav sample">
                <Container>
                    <Row>
                        <Col>
                            <div className="breadcrumb d-none d-lg-flex">
                                <Breadcrumb>
                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/auctions/"}>auctions</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/auctions/past-auctions/"}>Past auctions</Link></li>
                                    <li className="breadcrumb-item active"><a>Past Auction details</a></li>
                                </Breadcrumb>

                                {/* <span className="share social-share-wrapper">
                                    <ScrollAnimation animateIn='bounce' initiallyVisible={true}>
                                    <div className="d-flex align-items-center">
                                        <a className="d-flex align-items-center" href="javascript:void(0)" onClick={openShareicons}><i className="icon-share"></i></a> <a className="d-flex align-items-center" href="javascript:void(0)" onClick={openShareicons}>share this property</a>
                                    </div>
                                    </ScrollAnimation>
                                    {Shareicons &&
                                    <div onClick={openShareicons} className="property-share-social-icons mobile-details-socila-share">
                                        <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                            <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                        </FacebookShareButton>
                                        <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                            <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                        </TwitterShareButton>
                                        <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                            <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                        </LinkedinShareButton>
                                        <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                                            <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                        </WhatsappShareButton>
                                    </div>
                                    }
                                </span> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="pt-5 feature-section auction-details">
                <Container>
                    <Row>
                        <Col>
                            {/* <h1 className="mb-2">{data?.properties?.length > 0 ? dateformat(data?.properties[0]?.auctionDate, 'dddd, d mmmm yyyy') : "No"} Property Auction</h1> */}
                            <h1 className="mb-2">Past Auctions - {sortedProperties?.length > 0 ? dateformat(sortedProperties[0]?.auctionDate, 'dddd, d mmmm yyyy') : "No"}</h1> 
                        </Col>
                    </Row>

                    <Row className="auction-row mt-5">
                        {
                            sortedProperties && sortedProperties?.length > 0 && sortedProperties?.map((propertyItem, i) => {
                                // Past auctions url structure
                                let uriStr = CustomLinks.auction_property_for_sale+"/"+propertyItem?.slug+"-"+propertyItem?.id;
                                // Past auctions url structure

                                if(propertyItem?.display_address){
                                    return (
                                        <>
                                        <Col lg={4} xl={3} md={6} className="mb-5 auction_item">
                                            <AuctionCard
                                                propertyImages={propertyItem?.images}
                                                Offer={propertyItem?.status}
                                                propertyPrice={propertyItem?.price}
                                                propertyTitle={propertyItem?.title}
                                                propertyDisplayAddress={propertyItem?.display_address}
                                                propertyNegoId={propertyItem?.crm_negotiator_id}
                                                auctionUrl={uriStr}
                                                lotNumber={propertyItem?.extra?.lotNumber}
                                                building={propertyItem?.building}
                                                extra={propertyItem?.extra}
                                                page_url={CustomLinks.past_auction_details+id}
                                            />  
                                        </Col>
                                        </>
                                    )
                                }
                                
                            })
                        }
                    </Row>
                </Container>
            </section>

            <Connect department="auctions" />
        </Layout>
    )
}

export default PastAuctionsDetails