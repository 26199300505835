import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import './assets/styles/_index.scss'
import { postFormData } from "./api/Api"
import { phoneNumberValidation, Mail_Function, CustomLinks } from "../common/utils"
import { navigate } from "@reach/router"
// import axios from "axios"
// import * as qs from "query-string"

import $ from "jquery"

function MemberForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectError, setSelectError] = useState(false);

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  //console.log("props_formName", props.formLabel);

  var page_url = props.page_url ? props.page_url : props.location?.state?.page_url ? props.location?.state?.page_url : "";
  var type = props.type ? props.type : props.location?.state?.type ? props.location?.state?.type : "";
  var sub_type = props.sub_type ? props.sub_type : props.location?.state?.sub_type ? props.location?.state?.sub_type : "";
  var to_user_id = props.to_user_id ? props.to_user_id : props.location?.state?.to_user_id ? props.location?.state?.to_user_id : "";

  const fields = ([
      {
        element: "config",
        formname: props.formName ? props.formName : 'member',
        form_type: props.formName ? props.formName.toLowerCase() : 'member',
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
        email_temp_user: "member_user",
        email_temp_admin: "member_admin",
        email_server_func: "contact-member",
        event_tracking: props.formName ? props.formName.toLowerCase() : 'member',
        page_url: "/about/our-people",
        email_subject_user: "Staff Contact",
        email_subject_admin: "Staff Contact",
      },
      // {
      //   grpmd: "12",
      //   label: "Department",
      //   placeholder: "Select the department",
      //   name: "department",
      //   element: "select",
      //   required: true,
      //   values: ['Select', 'Commercial', 'Residential', 'Auctions'],
      //   labelClass: "annual-txt",
      //   class: "full_wdth_box",
      //   errorMsg: "Select the department."
      // },
      {
        grpmd: "12",
        label: "First Name",
        placeholder: "",
        name: "first_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed."
      },
      {
        grpmd: "12",
        label: "Last Name",
        placeholder: "",
        name: "last_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed."
      },
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM."
      },
      {
        grpmd: "12",
        label: "Telephone Number",
        placeholder: "",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890."
      },
      
      {
        grpmd: "12",
        label: "How can we help ?",
        placeholder: "Please type your enquiry...",
        name: "message",
        element: "textarea",
        class: "",
        required: true,
        rows:"4",
        labelClass: "annual-txt",
        errorMsg: "Enter the enquiry message."
      },
      {
        grpmd: "6",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Submit Enquiry",
        class: "btn-primary col-md-12",
        labelClass: "",
        customClass:"btn-wrapper "
      },
      {
        text: 'By clicking Submit Enquiry, you agree to our <a href="'+CustomLinks.terms_conditions+'" className="content-link" target="_blank">Terms</a> and <a href="'+CustomLinks.privacy_policy+'" target="_blank">Privacy Policy</a>.',
        element: "html",
        class: "content_r-m"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    if(event.type === "select"){
      //event.value = event.value.trimStart()
      setSelectedOptions(event);
    } else{
      if(event.target.name === "telephone"){
        var valid_phone = phoneNumberValidation(event.target.value);
        if(valid_phone){
          event.target.value = valid_phone;
        } else{
          event.target.value = '';
        }
      } else{
        event.target.value = event.target.value.trimStart()
      }
    }
  }

  useEffect(() => {
    if (token !== '') {

 const processFromData = async () => {

      let formData = new FormData();
      formvalues['g-recaptcha-response'] = token;
      formData.append('data', JSON.stringify(formvalues));

        if(formvalues.email){

          postFormData(formData).then(async apiRes => {
            window.grecaptcha.reset(); 
          });
          Mail_Function(formvalues);
          // tracking event
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'formSubmit',
            //'formType': 'form-'+fields[0].event_tracking,
            'formType': formvalues.interested_in,
            'formId': 'form-'+fields[0].event_tracking,
            'formName': fields[0].formname,
            'formLabel': props.formLabel ? props.formLabel.replace('Person', 'Person contact') : 'Person contact'
          });

          setShowerror(false);
          setTimeout(() => {
            sessionStorage.setItem("previousFormPage", window.location.pathname)
            navigate("/thank-you")
          }, 200);
          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
          
        }

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    form_obj_arr.filter(item => item[1].required === true).map(item =>{
      if(item[1].validationMessage !== ""){
        $(".error_msg."+item[1].name).addClass('show');
      } else{
        $(".error_msg."+item[1].name).removeClass('show');
      }
    })
    
    if(selectedOptions.length === 0){
      setSelectError(true)
      $(".error_msg.department").addClass('show');
    } else{
      setSelectError(false)
      $(".error_msg.department").removeClass('show');
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))
      
      json['name'] = json.first_name+' '+json.last_name;
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['extra'] = {"Interested in": json.interested_in};
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;
      json['extra'] = {"department": json.department, "to_email_id": json.to_email_id, "to_user_id": json.to_user_id, "subject" : json.email_subject_user};

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      setSelectError(false);
      setSelectedOptions([]);

      // reset form
      const form = event.target
      form.reset();
      
    }
  };

  //console.log("selectedOptions", selectedOptions);

  return (
    <div className="form">
    <div ref={myRef} />

    {/* {showerror && <div className="alert-error">
      <p>{fields[0].error_text}</p>
    </div>} */}

    {showthankyou && <div className="alert-success">
      <p>{fields[0].success_text}</p>
    </div>}

    

      <Form name={fields[0].formname} className="contact-form" action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={props.formLabel} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        <input type="hidden" name="to_user_id" value={props.to_user_id} />
        <input type="hidden" name="admin_email" value={"Yes"} />

        <input type="hidden" name="property_url" value={page_url} />
        <input type="hidden" name="type_label" value={type} />
        <input type="hidden" name="sub_type_label" value={sub_type} />
        
        <input type="hidden" name="bot-field" />
        <Form.Row>
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError ? true : '' }
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  grpmd={field.grpmd}
                  customClass={field.customClass}
                />
              );
            }
          })
        }
        </Form.Row>
      </Form>
    </div>
  );
}


const MemberFormPage = (props) => (
  <MemberForm  {...props}/>
)

export default MemberFormPage
